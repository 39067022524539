import axiosIns from './axios'

const mixin = {
  data() {
    return {
      dataTable: [],
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      loading: true,
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    async getItemsTableMixin(endpoint) {
      await axiosIns.get(endpoint).then(res => {
        this.dataTable = res.data.data
        this.pageOptions.itemsPerPage = res.data.meta.per_page
        this.pageOptions.pageCount = res.data.meta.last_page
        this.loading = false
      })

      const data = {
        itemsTable: this.dataTable,
        itemsPerPage: this.pageOptions.itemsPerPage,
        pageCount: this.pageOptions.pageCount,
        page: 1,
        loading: false,
      }

      return data
    },

    async nextPageMixin(endpoint) {
      this.loading = true
      await axiosIns.get(`${endpoint}?page=${this.pageOptions.page}`).then(res => {
        this.dataTable = res.data.data
        this.pageOptions.itemsPerPage = res.data.meta.per_page
        this.pageOptions.pageCount = res.data.meta.last_page
        this.loading = false
      })
    },
  },
}

export default mixin
